if ('objectFit' in document.documentElement.style === false) {
  var container = document.getElementsByClassName('cover-js');
  for (var i = 0; i < container.length; i++) {
    var imageSource = container[i].querySelector('img').src;
    container[i].querySelector('img').style.display = 'none';
    container[i].style.backgroundSize = 'cover';
    container[i].style.backgroundImage = 'url(' + imageSource + ')';
    container[i].style.backgroundPosition = 'center center';
  }
}
