$(document).ready(function () {


  // media queries
  const mq = [
    window.matchMedia("(max-width: 767px)"),
    window.matchMedia("(min-width: 768px) and (max-width: 1279px)"),
    window.matchMedia("(min-width: 1280px)")
  ];


  // resize events
  const scrollContent = $(".custom-scroll-js");

  $(window).on("resize", function () {
    if (mq[2].matches) {

      // positioning elements
      $(".section--exclusive .column--right").insertAfter($(".section--exclusive .column--left"));
      $(".section--footer h2").prependTo($(".section--footer .bottom_info"));

      // scroll content height fix
      scrollContent.css("height", $(window).outerHeight());

      // custom scroll
      if ($.fn.mCustomScrollbar) {
        scrollContent.mCustomScrollbar({
          mouseWheel: {
            scrollAmount: 250
          },
          keyboard: {
            scrollAmount: 5
          }
        });
      }

    } else {

      // positioning elements
      $(".section--exclusive .column--right").insertBefore($(".section--exclusive .column--left .exclusive_partners"));
      $(".section--footer h2").insertBefore($(".section--footer .bottom_info"));

      // scroll content height fix
      scrollContent.css("height", "");

      // custom scroll
      if ($.fn.mCustomScrollbar) {
        scrollContent.mCustomScrollbar("destroy");
      }

    }
  }).trigger("resize");


  // drop open and hide when click outside
  const
    links = ".dropdown-link-js",
    innerElements = ".dropdown-js",
    navClose = () => {
      $("body").removeClass("nav_animation");
      $(".nav").one("transitionend", function () {
        $("body").removeClass("nav_open");
      });
    };

  $(document).on("click", links, function (e) {
    if ($(this).hasClass("active")) {
      $(links).filter('a[href="' + this.hash + '"]').removeClass("active");
      $(innerElements).filter(this.hash).removeClass("open");
      if ($(this).hasClass("hamburger")) {
        navClose();
      }
    } else {
      $(links).removeClass("active").filter('a[href="' + this.hash + '"]').addClass("active");
      $(innerElements).removeClass("open").filter(this.hash).addClass("open");
      if ($(this).hasClass("hamburger")) {
        $("body").addClass("nav_open nav_animation");
      }
      $(links).add(innerElements).off();
    }
    e.preventDefault();
  }).on("mouseup touchstart keyup", function (e) {
    if ((!$(links).add(innerElements).is(e.target) && $(links).add(innerElements).has(e.target).length === 0) || e.keyCode === 27) {
      $(links).removeClass("active");
      $(innerElements).removeClass("open");
      navClose();
    }
  });


  // fullpage
  if ($.fn.fullpage) {
    $(".fullpage").fullpage({
      sectionSelector: ".section-js",
      normalScrollElements: ".nav, .site_logo, .hamburger, .custom-scroll-js:not(.mCS_no_scrollbar)",
      scrollingSpeed: 700,
      easingcss3: "cubic-bezier(0.455, 0.03, 0.515, 0.955)",
      responsiveWidth: mq[2].media.split(":").pop().slice(0, -3),
      afterLoad: function (anchorLink, index) {
        if (index === 1) {
          $("body").addClass("first-page");
        } else {
          $("body").removeClass("first-page");
        }
        if ((index !== 1) && (index !== $(".fullpage-wrapper .section").length)) {
          $("body").addClass("middle-page");
        } else {
          $("body").removeClass("middle-page");
        }
        if (index === $(".fullpage-wrapper .section").length) {
          $("body").addClass("last-page");
        } else {
          $("body").removeClass("last-page");
        }
      }
    });
  }


  // logo to home page
  $(".site_logo").on("click", function (e) {
    $.fn.fullpage.moveTo(1);
    e.preventDefault();
  });


  // scroll to page
  $(".url_scroll").on("click", function (e) {
    const targetDiv = $(this).attr("href");
    $.fn.fullpage.moveTo(targetDiv);
    e.preventDefault();
  });


  // mobile even fix
  $(".section--text:odd").addClass("section--even");


  // sliders
  if ($.fn.slick) {
    // works slider
    $(".works_slider_for").slick({
      autoplay: false,
      arrows: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      asNavFor: ".works_slider_nav",
      vertical: true,
      verticalSwiping: true,
      focusOnSelect: true,
      centerMode: true,
      centerPadding: "1000px",
      speed: 500,
      cssEase: "cubic-bezier(0.455, 0.03, 0.515, 0.955)",
      responsive: [{
        breakpoint: mq[2].media.split(":").pop().slice(0, -3),
        settings: {
          slidesToShow: 1,
          variableWidth: true,
          vertical: false,
          verticalSwiping: false,
          centerPadding: ""
        }
      }]
    });
    $(".works_slider_nav").slick({
      autoplay: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      asNavFor: ".works_slider_for",
      arrows: true,
      vertical: true,
      draggable: false,
      focusOnSelect: true,
      centerMode: true,
      centerPadding: "60px",
      useTransform: false,
      prevArrow: '<button type="button" class="slick-prev" aria-label="Previous"><i class="icon-arrow-down" aria-hidden="true"></i></button>',
      nextArrow: '<button type="button" class="slick-next" aria-label="Next"><i class="icon-arrow-up" aria-hidden="true"></i></button>',
      speed: 500
    });

    // process slider
    $(".process_slider_for").slick({
      autoplay: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      draggable: false,
      asNavFor: ".process_slider_nav",
      prevArrow: '<button type="button" class="slick-prev" aria-label="Previous"><i class="icon-arrow-left" aria-hidden="true"></i></button>',
      nextArrow: '<button type="button" class="slick-next" aria-label="Next"><i class="icon-arrow-right" aria-hidden="true"></i></button>',
      speed: 400,
      cssEase: "cubic-bezier(0.455, 0.03, 0.515, 0.955)",
      responsive: [{
        breakpoint: 768,
        settings: {
          arrows: false
        }
      }]
    });
    $(".process_slider_nav").slick({
      autoplay: false,
      slidesToShow: 6,
      slidesToScroll: 1,
      asNavFor: ".process_slider_for",
      draggable: false,
      arrows: false,
      speed: 1000,
      cssEase: "cubic-bezier(0.455, 0.03, 0.515, 0.955)",
      focusOnSelect: true,
      responsive: [{
        breakpoint: 700,
        settings: {
          slidesToShow: 5
        }
      }, {
        breakpoint: 550,
        settings: {
          slidesToShow: 4
        }
      }, {
        breakpoint: 450,
        settings: {
          slidesToShow: 3
        }
      }, {
        breakpoint: 350,
        settings: {
          slidesToShow: 2
        }
      }]
    });

    // team slider
    $(".team_slider_for").slick({
      autoplay: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      draggable: false,
      asNavFor: ".team_slider_nav",
      prevArrow: '<button type="button" class="slick-prev" aria-label="Previous"><i class="icon-arrow-left" aria-hidden="true"></i></button>',
      nextArrow: '<button type="button" class="slick-next" aria-label="Next"><i class="icon-arrow-right" aria-hidden="true"></i></button>',
      speed: 400,
      cssEase: "cubic-bezier(0.455, 0.03, 0.515, 0.955)"
    });
    $(".team_slider_nav").slick({
      autoplay: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      asNavFor: ".team_slider_for",
      draggable: false,
      arrows: false,
      speed: 1000,
      cssEase: "cubic-bezier(0.455, 0.03, 0.515, 0.955)",
      focusOnSelect: true,
      responsive: [{
        breakpoint: 1366,
        settings: {
          slidesToShow: 5
        }
      }, {
        breakpoint: 1000,
        settings: {
          slidesToShow: 4
        }
      }, {
        breakpoint: 650,
        settings: {
          slidesToShow: 3
        }
      }, {
        breakpoint: 520,
        settings: {
          slidesToShow: 2
        }
      }, {
        breakpoint: 410,
        settings: {
          slidesToShow: 1
        }
      }]
    });

    // articles slider
    $(".articles_slider").slick({
      autoplay: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      adaptiveHeight: true,
      draggable: false,
      prevArrow: '<button type="button" class="slick-prev" aria-label="Previous"><i class="icon-icon-left" aria-hidden="true"></i></button>',
      nextArrow: '<button type="button" class="slick-next" aria-label="Next"><i class="icon-icon-right" aria-hidden="true"></i></button>',
      speed: 400,
      cssEase: "cubic-bezier(0.455, 0.03, 0.515, 0.955)"
    });
  }


  // accordeon
  if ($(".faq_list").length) {
    const
      accordeonLinks = ".faq_list__toggle",
      accordeonContainers = ".faq_list__hidden";

    $(document).on("click", accordeonLinks, function (e) {
      if (!$(this).hasClass("active")) {
        $(accordeonLinks).removeClass("active");
        $(accordeonContainers).slideUp().promise().then(() => {
          $(accordeonLinks).filter(this).addClass("active").next().slideDown(function () {
            if (mq[0].matches) {
              $("html, body").animate({
                scrollTop: $(this).prev().offset().top
              }, 1000);
            }
          });
        });
      }
      e.preventDefault();
    });
  }


  // articles slider remove
  $(".articles_block__close").on("click", function (e) {
    $(this).parent().addClass("remove").one("transitionend", function () {
      $(this).remove();
    });
    e.preventDefault();
  });


  // form validation
  if ($.fn.validate) {
    $(".contact_form").validate();
    $(".newsletter").validate();
  }


  // parallax
  if ($(".parallax-js").length) {
    let parallaxInstance;

    $(".parallax-js").each(function () {
      parallaxInstance = new Parallax($(this).get(0), {
        hoverOnly: true,
        frictionX: 0.2,
        frictionY: 0.8
      });
    });
  }


});
